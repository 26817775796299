import http from "../http";

export const pvcList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/persistentvolumeclaims`
  });
};

export const pvList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/persistentvolumes`
  });
};

export const pvcListByLocation = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/persistentvolumeclaims`
  });
};

export const pvcSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/persistentvolumeclaims`,
    data,
    headers
  });
};

export const pvSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/persistentvolumes`,
    data,
    headers
  });
};

export const pvcDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/persistentvolumeclaims/${data.name}`
  });
};

export const pvcDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/persistentvolumeclaims/${data.persistentvolumeclaim}`
  });
};

export const pvDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/persistentvolumes/${data.name}`
  });
};

export const pvPods = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/persistentvolumeclaims/${data.persistentvolumeclaim}/pods`
  });
};

export const pvRelatedPvc = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/persistentvolume/${data.persistentvolume}/persistentvolumeclaims`
  });
};

export const pvDetail = data => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/persistentvolumes/${data.persistentvolume}`
  });
};
